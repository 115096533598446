exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-takeover-case-study-js": () => import("./../../../src/pages/account-takeover/case-study.js" /* webpackChunkName: "component---src-pages-account-takeover-case-study-js" */),
  "component---src-pages-account-takeover-js": () => import("./../../../src/pages/account-takeover.js" /* webpackChunkName: "component---src-pages-account-takeover-js" */),
  "component---src-pages-attack-surface-reduction-js": () => import("./../../../src/pages/attack-surface-reduction.js" /* webpackChunkName: "component---src-pages-attack-surface-reduction-js" */),
  "component---src-pages-bandwidth-case-study-js": () => import("./../../../src/pages/bandwidth/case-study.js" /* webpackChunkName: "component---src-pages-bandwidth-case-study-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-know-more-js": () => import("./../../../src/pages/blogs/know-more.js" /* webpackChunkName: "component---src-pages-blogs-know-more-js" */),
  "component---src-pages-blogs-threat-alerts-js": () => import("./../../../src/pages/blogs/threat-alerts.js" /* webpackChunkName: "component---src-pages-blogs-threat-alerts-js" */),
  "component---src-pages-blogs-value-series-js": () => import("./../../../src/pages/blogs/value-series.js" /* webpackChunkName: "component---src-pages-blogs-value-series-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-channel-partners-index-js": () => import("./../../../src/pages/channel-partners/index.js" /* webpackChunkName: "component---src-pages-channel-partners-index-js" */),
  "component---src-pages-channel-partners-register-deal-js": () => import("./../../../src/pages/channel-partners/register-deal.js" /* webpackChunkName: "component---src-pages-channel-partners-register-deal-js" */),
  "component---src-pages-channel-partners-register-deal-new-js": () => import("./../../../src/pages/channel-partners/register-deal-new.js" /* webpackChunkName: "component---src-pages-channel-partners-register-deal-new-js" */),
  "component---src-pages-client-stories-js": () => import("./../../../src/pages/client-stories.js" /* webpackChunkName: "component---src-pages-client-stories-js" */),
  "component---src-pages-client-support-js": () => import("./../../../src/pages/client-support.js" /* webpackChunkName: "component---src-pages-client-support-js" */),
  "component---src-pages-cloud-monitoring-case-study-js": () => import("./../../../src/pages/cloud-monitoring/case-study.js" /* webpackChunkName: "component---src-pages-cloud-monitoring-case-study-js" */),
  "component---src-pages-competitor-comparison-js": () => import("./../../../src/pages/competitor-comparison.js" /* webpackChunkName: "component---src-pages-competitor-comparison-js" */),
  "component---src-pages-compliance-support-case-study-js": () => import("./../../../src/pages/compliance-support/case-study.js" /* webpackChunkName: "component---src-pages-compliance-support-case-study-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cyberdna-termsandcondition-js": () => import("./../../../src/pages/cyberdna/termsandcondition.js" /* webpackChunkName: "component---src-pages-cyberdna-termsandcondition-js" */),
  "component---src-pages-cyberdna-termsandconditions-js": () => import("./../../../src/pages/cyberdna/termsandconditions.js" /* webpackChunkName: "component---src-pages-cyberdna-termsandconditions-js" */),
  "component---src-pages-defense-js": () => import("./../../../src/pages/defense.js" /* webpackChunkName: "component---src-pages-defense-js" */),
  "component---src-pages-direct-sales-partner-terms-and-conditions-js": () => import("./../../../src/pages/direct-sales-partner-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-direct-sales-partner-terms-and-conditions-js" */),
  "component---src-pages-evolving-threat-js": () => import("./../../../src/pages/evolving-threat.js" /* webpackChunkName: "component---src-pages-evolving-threat-js" */),
  "component---src-pages-financial-services-js": () => import("./../../../src/pages/financial-services.js" /* webpackChunkName: "component---src-pages-financial-services-js" */),
  "component---src-pages-for-it-ops-js": () => import("./../../../src/pages/for-it-ops.js" /* webpackChunkName: "component---src-pages-for-it-ops-js" */),
  "component---src-pages-for-security-js": () => import("./../../../src/pages/for-security.js" /* webpackChunkName: "component---src-pages-for-security-js" */),
  "component---src-pages-gb-2-k-7-fia-ta-w-101-e-o-39-pn-8-b-js": () => import("./../../../src/pages/GB2K7FiaTaW101eO39pn8B.js" /* webpackChunkName: "component---src-pages-gb-2-k-7-fia-ta-w-101-e-o-39-pn-8-b-js" */),
  "component---src-pages-healthcare-services-js": () => import("./../../../src/pages/healthcare-services.js" /* webpackChunkName: "component---src-pages-healthcare-services-js" */),
  "component---src-pages-i-n-3-wnf-5-x-6-ijl-a-1-z-dw-6-z-k-8-h-js": () => import("./../../../src/pages/iN3WNF5x6ijlA1ZDw6zK8H.js" /* webpackChunkName: "component---src-pages-i-n-3-wnf-5-x-6-ijl-a-1-z-dw-6-z-k-8-h-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managed-endpoint-protection-js": () => import("./../../../src/pages/managed-endpoint-protection.js" /* webpackChunkName: "component---src-pages-managed-endpoint-protection-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-mdr-services-js": () => import("./../../../src/pages/mdr-services.js" /* webpackChunkName: "component---src-pages-mdr-services-js" */),
  "component---src-pages-navtree-clients-tree-js": () => import("./../../../src/pages/navtree/clients-tree.js" /* webpackChunkName: "component---src-pages-navtree-clients-tree-js" */),
  "component---src-pages-navtree-company-tree-js": () => import("./../../../src/pages/navtree/company-tree.js" /* webpackChunkName: "component---src-pages-navtree-company-tree-js" */),
  "component---src-pages-navtree-platform-tree-js": () => import("./../../../src/pages/navtree/platform-tree.js" /* webpackChunkName: "component---src-pages-navtree-platform-tree-js" */),
  "component---src-pages-navtree-service-tree-js": () => import("./../../../src/pages/navtree/service-tree.js" /* webpackChunkName: "component---src-pages-navtree-service-tree-js" */),
  "component---src-pages-navtree-solutions-tree-js": () => import("./../../../src/pages/navtree/solutions-tree.js" /* webpackChunkName: "component---src-pages-navtree-solutions-tree-js" */),
  "component---src-pages-o-2-l-rr-931-mn-594-pl-7-cp-x-9-xl-js": () => import("./../../../src/pages/o2LRr931Mn594Pl7CpX9Xl.js" /* webpackChunkName: "component---src-pages-o-2-l-rr-931-mn-594-pl-7-cp-x-9-xl-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-resources-battlecards-js": () => import("./../../../src/pages/resources/battlecards.js" /* webpackChunkName: "component---src-pages-resources-battlecards-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-solution-briefs-js": () => import("./../../../src/pages/resources/solution-briefs.js" /* webpackChunkName: "component---src-pages-resources-solution-briefs-js" */),
  "component---src-pages-resources-success-stories-js": () => import("./../../../src/pages/resources/success-stories.js" /* webpackChunkName: "component---src-pages-resources-success-stories-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-risk-reduction-js": () => import("./../../../src/pages/risk-reduction.js" /* webpackChunkName: "component---src-pages-risk-reduction-js" */),
  "component---src-pages-secure-the-high-ground-js": () => import("./../../../src/pages/secure-the-high-ground.js" /* webpackChunkName: "component---src-pages-secure-the-high-ground-js" */),
  "component---src-pages-security-maturity-case-study-js": () => import("./../../../src/pages/security-maturity/case-study.js" /* webpackChunkName: "component---src-pages-security-maturity-case-study-js" */),
  "component---src-pages-security-threat-audit-js": () => import("./../../../src/pages/security-threat-audit.js" /* webpackChunkName: "component---src-pages-security-threat-audit-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-serviceterms-js": () => import("./../../../src/pages/serviceterms.js" /* webpackChunkName: "component---src-pages-serviceterms-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-the-platform-js": () => import("./../../../src/pages/the-platform.js" /* webpackChunkName: "component---src-pages-the-platform-js" */),
  "component---src-pages-us-da-468-x-pd-v-17-l-jd-9-pt-3-wk-65-js": () => import("./../../../src/pages/us/da468XPdV17lJD9PT3wk65.js" /* webpackChunkName: "component---src-pages-us-da-468-x-pd-v-17-l-jd-9-pt-3-wk-65-js" */),
  "component---src-pages-us-ga-download-js": () => import("./../../../src/pages/us/ga-download.js" /* webpackChunkName: "component---src-pages-us-ga-download-js" */),
  "component---src-pages-us-gated-asset-form-success-js": () => import("./../../../src/pages/us/gated-asset-form-success.js" /* webpackChunkName: "component---src-pages-us-gated-asset-form-success-js" */),
  "component---src-pages-us-public-sector-js": () => import("./../../../src/pages/us-public-sector.js" /* webpackChunkName: "component---src-pages-us-public-sector-js" */),
  "component---src-pages-uw-5-i-91-yi-6-wno-8-h-3-r-po-ovo-l-js": () => import("./../../../src/pages/Uw5I91Yi6Wno8H3RPoOvoL.js" /* webpackChunkName: "component---src-pages-uw-5-i-91-yi-6-wno-8-h-3-r-po-ovo-l-js" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-plocks-uber-breach-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/plocks/uber-breach.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-plocks-uber-breach-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-act-with-integrity-and-respect-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/act-with-integrity-and-respect.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-act-with-integrity-and-respect-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-an-analysis-of-the-implications-of-caffeine-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/an-analysis-of-the-implications-of-caffeine.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-an-analysis-of-the-implications-of-caffeine-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-bad-listening-harms-relationships-and-cyber-programs-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/bad-listening-harms-relationships-and-cyber-programs.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-bad-listening-harms-relationships-and-cyber-programs-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-difference-between-vulnerability-scanning-and-penetration-testing-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/difference-between-vulnerability-scanning-and-penetration-testing.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-difference-between-vulnerability-scanning-and-penetration-testing-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-dont-sign-multi-year-contracts-attached-to-products-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/dont-sign-multi-year-contracts-attached-to-products.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-dont-sign-multi-year-contracts-attached-to-products-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-faith-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/faith.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-faith-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-fast-remediation-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/fast-remediation.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-fast-remediation-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-fireeye-attack-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/fireeye-attack.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-fireeye-attack-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-first-to-detect-but-never-publicize-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/first-to-detect-but-never-publicize.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-first-to-detect-but-never-publicize-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-great-security-solves-unexpected-problems-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/great-security-solves-unexpected-problems.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-great-security-solves-unexpected-problems-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-highest-number-of-phishing-attacks-ever-recorded-this-year-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/highest-number-of-phishing-attacks-ever-recorded-this-year.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-highest-number-of-phishing-attacks-ever-recorded-this-year-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-kaseya-vsa-supply-chain-attack-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/kaseya-vsa-supply-chain-attack.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-kaseya-vsa-supply-chain-attack-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-log-4-shell-vulnerability-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/log4shell-vulnerability.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-log-4-shell-vulnerability-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-exchange-critical-0-day-exploits-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/microsoft-exchange-critical-0-day-exploits.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-exchange-critical-0-day-exploits-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-exchange-server-vulnerability-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/microsoft-exchange-server-vulnerability.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-exchange-server-vulnerability-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-releases-patch-for-severe-zerologon-vulnerability-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/microsoft-releases-patch-for-severe-zerologon-vulnerability.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-microsoft-releases-patch-for-severe-zerologon-vulnerability-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-never-quit-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/never-quit.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-never-quit-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-operational-security-opsec-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/operational-security-opsec.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-operational-security-opsec-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-printnightmare-vulnerability-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/printnightmare-vulnerability.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-printnightmare-vulnerability-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-protecting-against-malicious-usb-devices-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/protecting-against-malicious-usb-devices.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-protecting-against-malicious-usb-devices-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-qakbot-continues-to-menace-corporate-environments-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/qakbot-continues-to-menace-corporate-environments.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-qakbot-continues-to-menace-corporate-environments-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-quick-security-wins-for-zero-dollars-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/quick-security-wins-for-zero-dollars.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-quick-security-wins-for-zero-dollars-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-recent-attacks-require-an-updated-mindset-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/recent-attacks-require-an-updated-mindset.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-recent-attacks-require-an-updated-mindset-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-solarwinds-orion-and-office-365-attack-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/solarwinds-orion-and-office-365-attack.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-solarwinds-orion-and-office-365-attack-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-sonicwall-email-security-and-pulse-secure-vpn-vulnerabilities-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/sonicwall-email-security-and-pulse-secure-vpn-vulnerabilities.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-sonicwall-email-security-and-pulse-secure-vpn-vulnerabilities-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-the-power-of-the-unlimited-breach-response-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/the-power-of-the-unlimited-breach-response.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-the-power-of-the-unlimited-breach-response-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-timeline-of-security-and-the-secret-to-why-cybersecurity-fails-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/timeline-of-security-and-the-secret-to-why-cybersecurity-fails.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-timeline-of-security-and-the-secret-to-why-cybersecurity-fails-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-vulnerability-in-vcenter-server-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/vulnerability-in-vcenter-server.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-vulnerability-in-vcenter-server-mdx" */),
  "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-why-us-based-socs-are-important-to-us-based-companies-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/content/posts/why-us-based-socs-are-important-to-us-based-companies.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-opt-build-repo-content-posts-why-us-based-socs-are-important-to-us-based-companies-mdx" */)
}

